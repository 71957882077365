
import { defineComponent, onMounted, reactive, ref } from 'vue'
import { formatterDollars } from '@/utils/formatter'
import { formatTime } from '@/utils/dateTime'
import CustomDialog from '@/components/orders/CustomDialog.vue'
import {
  getWarningContent,
  getOrderStatusColor,
  getAddressStatusColor,
} from '@/utils/orderUtils'
import { addressTitleMap, statusTitleMap } from '@/utils/constants'
import Pagination from '@/components/common/Pagination.vue'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { InfoFilled } from '@element-plus/icons'

export default defineComponent({
  components: {
    CustomDialog,
    Pagination,
    InfoFilled,
  },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  setup(props) {
    const isLoading = ref(false)
    const columns = [
      {
        key: 'name',
        title: 'Order',
        dataIndex: 'orderName',
        slots: { title: 'customTitle', customRender: 'orderName' },
        minWidth: '15',
      },
      {
        title: 'Customer',
        dataIndex: 'fullName',
        minWidth: '10',
      },
      {
        title: 'FFM status',
        dataIndex: 'status',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Revenue',
        dataIndex: 'revenue',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'FFM Cost',
        dataIndex: 'sellerAmount',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Total fees',
        dataIndex: 'total_fees',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Profit',
        dataIndex: 'profit',
        minWidth: '10',
        align: 'center',
      },
      {
        title: 'Estimated Profit',
        dataIndex: 'estimatedProfit',
        minWidth: '10',
        align: 'center',
      },
    ]
    const params = reactive<any>({
      page: 1,
      limit: 10,
      orderDate: {
        startDate: props.startDate,
        endDate: props.endDate,
        timezone: 'America/Los_Angeles',
      },
    })

    const { getOrders, state } = useOrderRepositories()
    onMounted(async () => {
      isLoading.value = true
      await getOrders(params)
      isLoading.value = false
    })
    return {
      isLoading,
      columns,
      addressTitleMap,
      statusTitleMap,
      formatterDollars,
      formatTime,
      getWarningContent,
      getOrderStatusColor,
      getAddressStatusColor,
      params,
      getOrders,
      state,
    }
  },
})
