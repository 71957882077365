
import { defineComponent, onMounted, ref, watch, computed } from 'vue'
import moment from 'moment'
import { Chart, Grid, Bar, Tooltip } from 'vue3-charts'
import useProfitsRepositories from '@/repositories/useProfitsRepositories'
import { shortcutsDatetimePicker, } from '@/utils/dateTime'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import DetailProfit from './components/DetailProfit.vue'

export default defineComponent({
  name: 'EstimatedProfit',
  components: { Chart, Grid, Bar, Tooltip, sectionLayoutContent, DetailProfit },
  setup() {
    const breadcrumbs = ['Estimated Profit', '']
    const content = computed(() => {
      return { breadcrumbs }
    })

    const { getExpectedProfit } = useProfitsRepositories()

    // config graph
    const direction = ref('horizontal')

    const margin = ref({
      left: 0,
      top: 20,
      right: 20,
      bottom: 0
    })

    const axis = ref({
      primary: {
        type: 'band'
      },
      secondary: {
        domain: ['dataMin', 'dataMax + 100'],
        type: 'linear',
        ticks: 8
      }
    })
    const dataShow = ref<any[]>([])

    // Config filter
    const option = ref('Today')
    const dateRangeOption = [
      {
        id: 1,
        label: 'Today',
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        id: 2,
        label: '7 days',
        startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
      {
        id: 3,
        label: '30 days',
        startDate: `${(new Date()).getFullYear()}-${(new Date()).getMonth()}-${(new Date()).getDate()}`,
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      },
    ]

    const setOptionFilter = (item: any) => {
      option.value = item.label
      params.value = {
        endDate: item.endDate,
        startDate: item.startDate,
      }
    }

    // get data
    const params = ref({
      endDate: moment(new Date()).format('YYYY-MM-DD'),
      startDate: moment(new Date()).format('YYYY-MM-DD'),
    })

    const loadProfit = async () => {
      dataShow.value = []
      try {
        const { data } = (await getExpectedProfit(params.value)) as any

        if (data.data.length > 0) {
          data.data.forEach((item: any) => {
            let obj = {
              day: item.date,
              profit: item.totalProfit,
              estimatedProfit: item.totalEstimatedProfit,
            }
            dataShow.value.push(obj)
          })
        }
      } catch (error) {
        console.log('🚀 ~ file: Default.vue:261 ~ loadProfit ~ error:', error)
      }
    }
    onMounted(() => {
      loadProfit()
    })
    watch(params, loadProfit)

    return {
      content,
      direction,
      margin,
      axis,
      params,
      dataShow,
      loadProfit,
      shortcutsDatetimePicker,
      dateRangeOption,
      option,
      setOptionFilter
    }
  }
})
